<template>
  <ion-page id="rewardDetail">
    <ion-modal @didDismiss="showQrcode = false" :is-open="showQrcode" css-class="tcc-modal">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content reward-detail">
            <div class="icon-box">
              <img class="icon-img" src="@/assets/scan.png" />
            </div>
            <p class="font-18 main-color font-b">
              {{ rewardDetail.name }}
            </p>
            <div class="qrcode">
              <qrcode-vue :value="redeemQrcode" :size="size" level="H" />
            </div>
            <p class="font-18 black-color">{{ redeemQrcode }}</p>
            <ion-button expand="block" class="font-b" @click="downEvent"> Done (<van-count-down :time="time" ref="countDown" format="mm:ss" @finish="finish" />) </ion-button>
            <div class="btn-plain" @click="showQrcode = false">
              <span>Cancel</span>
            </div>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-modal @didDismiss="redeemPop = false" :is-open="redeemPop" css-class="tcc-modal">
      <div class="model-box">
        <div class="ion-page">
          <div class="modal-content friend-modal">
            <p class="font-18 font-b main-color">
              Congratulations! <br />
              <span style="display: block; margin-top: 20px" class="Medium-666"
                >You have earned enough stamps and qualified for redemption. Please go to your nearby Ritchies store and redeem your reward at checkout counter!</span
              >
            </p>
            <ion-button style="margin-top: 38px" class="Bold" expand="block" @click="redeemPop = false">I Know</ion-button>
          </div>
        </div>
      </div>
    </ion-modal>
    <ion-content :fullscreen="true">
      <topbar
        :class="{ 'gradient30 fixed': !$store.state.isPC, 'top-bar-white': $store.state.isPC }"
        :bigtitle="false"
        :showBack="true"
        :showClose="false"
        :share="true"
        :shareData="shareData"
      ></topbar>

      <div class="pc-box margin-top-0 pc-box2">
        <div class="pc-min-content-pct">
          <div class="pc-page-title" v-if="$store.state.isPC">Rewards</div>

          <div class="content">
            <div style="border-bottom: none" :class="{ 'pc-content-flex': $store.state.isPC }">
              <div class="coupon-img">
                <div class="rewardimg-box">
                  <img v-lazy="rewardDetail.image" :key="rewardDetail.image" />
                  <i class="icon-like red likeReward" v-if="rewardDetail.isLike" @click="likeEvent(rewardDetail)"></i>
                  <i class="icon-unlike likeReward" v-else @click="likeEvent(rewardDetail)"></i>
                </div>
              </div>

              <div style="flex: 1; margin-top: -20px">
                <div class="coupon-info">
                  <div class="coupon-name main-color font-24 Bold pc-font-28">
                    <span>{{ rewardDetail.name }}</span>
                  </div>

                  <div class="hr"></div>
                  <div class="period">
                    <label class="Bold pc-font-28">Status</label>
                    <div>
                      <p class="" :class="statusColor[rewardDetail.status - 1]">
                        {{ giftStatusText[rewardDetail.status - 1] }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="tandC">
                  <div class="title Bold pc-font-28">Rewards details</div>
                  <showMore :content="rewardDetail.descr" :showHeight="!$store.state.isPC ? 70 : 68"></showMore>
                </div>

                <div class="hr"></div>

                <div class="tandC">
                  <div class="title Bold pc-font-28">Rewards</div>
                  <div class="ruleBox" v-for="(item, index) in rewardDetail.exchangeTypeList" :key="index">
                    <div class="item-table" style="width: 100%; padding: 20px">
                      <div class="tdcell" style="white-space: nowrap">
                        <span class="font-24 Bold main-color">{{ item.stampQty }} </span>
                        <span class="font-14 Bold main-color"> stamps</span>
                        <span class="cash font-14" v-show="item.cashQty">+${{ item.cashQty }}</span>
                      </div>
                      <div class="tdcell" style="width: 110px">
                        <ion-button :disabled="redeemBtnIsDisabled(item)" v-if="!$store.state.isPC" class="redeemNow Blod" expand="block" color="secondary" size="small" @click="redeemEvent(item)"
                          >Redeem Now</ion-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="height50"></div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent, IonButton, IonModal } from "@ionic/vue";
import config from "../config";
import topbar from "@/components/topbar.vue";
import QrcodeVue from "qrcode.vue";
import showMore from "@/components/showMore.vue";
export default {
  name: "rewardDetail",
  mixins: [config.globalMixin],
  data() {
    return {
      rewardDetail: {},
      giftStatusText: ["Coming soon", "Available", "Low stock", "Out of stock", "Expired", "Inactive"],
      statusColor: ["Expired", "availableNow", "lowStock", "outOfStock", "Expired", "Inactive"],
      showQrcode: false,
      redeemQrcode: "",
      size: 150,
      time: 600 * 1000,
      shareData: {},
      redeemPop: false,
    };
  },
  components: { IonContent, IonPage, topbar, IonButton, IonModal, QrcodeVue, showMore },
  methods: {
    async init() {
      const objdata = this.getAuthObj();
      objdata.giftId = this.$route.params.id;
      objdata.campaignId = this.$route.params.campaignId;
      objdata.apiUrl = this.apiConfig.getGiftDetail;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.rewardDetail = data.data.result;
      this.shareData = {
        title: this.$store.state.appName + " - Easy peasy! Redeem reward now!",
        description: `${this.rewardDetail.name}
        Redemption Period: ${this.$route.query.redemption}`,
        image: this.rewardDetail.image,
      };
    },
    backEvent() {
      this.$router.push({
        path: "/stampCardDetail/" + this.$route.params.campaignId,
      });
    },
    showMore(type) {
      console.log(this.$refs[type]);
      this.$refs[type].style.height = "auto";
    },
    redeemBtnIsDisabled(item) {
      if ((this.rewardDetail.status == 2 || this.rewardDetail.status == 3) && item.stampQty <= this.$route.query.stamps) {
        return false;
      } else {
        return true;
      }
    },
    async redeemEvent(reward) {
      this.sendGAEvent("Click", "Reward Detail", "Redeem Now");

      this.redeemPop = true;
      //   this.$router.push({
      //     path: "/wallet",
      //   });
      console.log(reward);
      //   const objdata = this.getAuthObj();
      //   objdata.campaignGiftMapId = this.$route.params.id;
      //   objdata.userStampCardId = this.$route.query.stampCardId;
      //   objdata.campaignGiftExchangeTypeId = reward.campaignGiftExchangeTypeId;
      //   objdata.apiUrl = this.apiConfig.getUserGiftRedeemCode;
      //   const data = await this.$store.dispatch("apiGetEvent", objdata);
      //   if (!data) {
      //     return;
      //   }
      //   if (data == this.TIMEOUT_CODE) {
      //     this.timeoutEvent();
      //     return;
      //   }
      //   this.redeemQrcode = data.data.result.redeemCode;
      //   this.showQrcode = true;
      //   this.time = 600 * 1000;
    },
    downEvent() {
      // this.redeemCodeEvent();
      this.showQrcode = false;
    },
    async redeemCodeEvent() {
      const objdata = this.getAuthObj();
      objdata.redeemCode = this.redeemQrcode;
      objdata.apiUrl = this.apiConfig.redeemGift;
      const data = await this.$store.dispatch("apiPutEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
    },
    finish() {
      this.showQrcode = false;
    },
    async likeEvent(reward) {
      const objdata = this.getAuthObj();
      objdata.campaignGiftMapId = reward.id;
      objdata.type = reward.isLike ? 0 : 1;
      objdata.apiUrl = this.apiConfig.likeGift;
      const data = await this.$store.dispatch("apiFormEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      if (!reward.isLike) {
        this.toastEvent("Liked this reward");
      }
      reward.isLike = !reward.isLike;
    },
  },
  ionViewWillEnter() {
    this.init();
    this.sendGAPageView("Reward Detail");
  },
  ionViewWillLeave() {
    this.$store.state.exitApp = false;
    this.showQrcode = false;
  },
};
</script>
